import React, {Component} from "react";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";

import standardFormData from "../components/standard_form_data";
import UsefulSegment from "../layouts/useful/useful_segment";
import AddUsefulForm from "../layouts/useful/add_useful_form";
import queryString from "../components/query_string";
import UsefulSearch from "../layouts/useful/useful_search";

const API_FETCH_USEFUL = "https://www.pinetreesra.co.uk/api/useful/fetch"
const API_ADD_USEFUL = "https://www.pinetreesra.co.uk/api/useful/addUseful"

class UsefulInfo extends Component {
    constructor(props) {
        super(props);
        this.fetch = this.fetch.bind(this);
        this.categories = this.categories.bind(this);
        this.search = this.search.bind(this);

        this.subCategories = this.subCategories.bind(this);
        this.usefulItems = this.usefulItems.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.fetch();
    }

    state = {
        usefulList: [],
        filteredList: [],
        activeIndex: -1,
        highlightIndex: -1,
        dataLoaded: false,
        searchTerm: ""
    }

    scrollTo() {
        let index = this.state.highlightIndex;
        if (index !== undefined && index !== -1) {
            let el = document.getElementById("id" + index);
            if (el !== null) {
                el.scrollIntoView(true);
            }
        }
    }

    fetch() {
        if (!this.props.loggedIn) {
            return;
        }

        let url = API_FETCH_USEFUL;
        if (this.props.isAdmin) {
            url = url + "/admin";
        }

        fetch(url, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, usefulList: data.newUsefulResults, filteredList: data.newUsefulResults}, () => {
                let t = queryString("id");
                if (t != null) {
                    this.setState({highlightIndex: t, filteredList: this.state.filteredList.filter((obj) => obj.id === t)});
                }
            });
        }));
    }

    addNew() {
        fetch(API_ADD_USEFUL, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({usefulList: [], filteredList: []});
            this.fetch();
        }));
    }

    accordionClick = (e, section) => {
        this.setState({activeIndex: section.index === this.state.activeIndex ? -1 : section.index})
    }

    categories() {
        let ret = [];
        this.state.filteredList.map((obj) => {
                if (!ret.includes(obj.category)) {
                    ret.push(obj.category)
                }
                return true;
            }
        );
        return ret;
    }

    subCategories(category) {
        let ret = [];
        this.state.filteredList.filter((obj) => {
            if (obj.category === category && !ret.includes(obj.sub_category)) {
                ret.push(obj.sub_category);
            }
            return true;
        })

        return ret;
    }

    usefulItems(category, subcategory) {
        return this.state.filteredList.filter((obj) => obj.category === category && (obj.sub_category === subcategory || obj.sub_category === null));
    }

    search(newSearchTerm) {
        this.setState({
            searchTerm: newSearchTerm, filteredList: this.state.usefulList.filter((obj) => {
                    let words = newSearchTerm.toLowerCase().split(" ");
                    let found = true;
                    for (let word of words) {
                        found = found && (
                            (obj !== undefined && obj.category.toLowerCase().indexOf(word) > -1) ||
                            (obj !== undefined && obj.sub_category != null && obj.sub_category.toLowerCase().indexOf(word) > -1) ||
                            (obj !== undefined && obj.useful_title != null && obj.useful_title.toLowerCase().indexOf(word) > -1) ||
                            (obj !== undefined && obj.contact_number != null && obj.contact_number.toLowerCase().indexOf(word) > -1) ||
                            (obj !== undefined && obj.web_link != null && obj.web_link.toLowerCase().indexOf(word) > -1) ||
                            (obj !== undefined && obj.description != null && obj.description.toLowerCase().indexOf(word) > -1)
                        );
                    }
                    return found;
                }
            )
        });
    }

    render() {
        return (
            <Container className={"full"}>
                <Header as="h1">Useful contacts and information</Header>
                <Message icon={{name: "warning sign", size: "large", color: "green"}} color="yellow"
                         content="PTRA offers this information in good faith, without prejudice or liability.
                The referrers are mentioned for reference purposes only"
                />


                <AddUsefulForm props={this.props} categories={this.categories()} subCategories={this.subCategories}/>
                <UsefulSearch searchTerm={this.state.searchTerm} search={this.search}/>

                <Accordion styled fluid>
                    {this.categories().map((categoryKey, i) =>
                        <div key={categoryKey + i}>
                            <Accordion.Title
                                index={i}
                                onClick={this.accordionClick}
                                style={{color: "#555555"}}
                            >
                                <Header><Icon name='dropdown'/>{categoryKey}</Header>
                            </Accordion.Title>
                            <Accordion.Content active={this.state.activeIndex === i || this.state.usefulList.length !== this.state.filteredList.length}>

                                {this.subCategories(categoryKey).map((subkey, j) =>
                                    <div key={categoryKey + subkey + j} style={{marginLeft: subkey === "__none" ? "0" : "35px"}}>
                                        {subkey !== "__none" &&
                                        <Header>{subkey}</Header>
                                        }
                                        {this.usefulItems(categoryKey, subkey).map((obj, k) =>
                                            <UsefulSegment
                                                usefulItem={obj}
                                                key={obj.id}
                                                loggedInUser={this.props.loggedInUser}
                                                isAdmin={this.props.isAdmin}
                                                highlightIndex={this.state.highlightIndex}
                                            />
                                        )}
                                    </div>
                                )}
                            </Accordion.Content>
                        </div>
                    )}
                </Accordion>
            </Container>
        )
    }

}

export default UsefulInfo