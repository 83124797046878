import React, {Component} from "react";
import 'semantic-ui-css/semantic.min.css'
import {loadReCaptcha} from 'react-recaptcha-google'
import {Cookies, withCookies} from "react-cookie/";
import {instanceOf} from "prop-types";
import {BrowserRouter as Router} from "react-router-dom";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Footer from "./common/footer";
import MenuFull from "./components/menu_full";
import MenuMobile from "./components/menu_mobile";
import Routing from "./components/routing";
import RouteChangeTracker from "./components/route_change_tracker";
import ReactGA from 'react-ga';

import "./assets/css/home.css"

ReactGA.initialize("G-L06EE7VDC5", {gaOptions: {cookieFlags: 'SameSite=None;Secure'}});
ReactGA.pageview(window.location.pathname);

const menuItems = {
    left: [{name: "Home", icon: {color: "orange", name: "home", size: "large"}, to: "/home"}, {
        name: "About",
        to: "/about"
    }, {name: "History", to: "/history"}, {name: "Neighbourhood Watch", to: "/neighbourhoodWatch"}, {
        name: "Members",
        to: "/members"
    }, {name: "Contact", to: "/contact"}], right: [{
        name: "Photos",
        icon: {color: "green", name: "camera retro", size: "large", inverted: true},
        to: "/photos",
        newFeature: true
    }, {
        name: "ToDo",
        icon: {color: "red", name: "clipboard list", size: "large", inverted: true},
        to: "/todo",
        newFeature: true
    }, {name: "News", icon: {color: "pink", name: "newspaper outline", size: "large", inverted: true}, to: "/news"}, {
        name: "FAQs",
        icon: {color: "purple", name: "question circle outline", size: "large", inverted: true},
        to: "/faq"
    }, {name: "Useful", icon: {color: "blue", name: "phone", size: "large", inverted: true}, to: "/usefulInfo"}, {
        name: "Events",
        icon: {color: "orange", name: "calendar alternate outline", size: "large", inverted: true},
        to: "/events",
        newFeature: true
    }]
}

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    state = {
        loggedInUser: this.fetchCookie(),
        loggedIn: this.fetchCookie() !== null,
        isAdmin: this.fetchCookie() !== null && (this.fetchCookie().member_type === "committee" || this.fetchCookie().member_type === "admin"),
        error: []
    }

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        loadReCaptcha();
    }

    fetchCookie() {
        let c = this.props.cookies.get("user");
        if (c !== undefined && c.length > 0) {
            c = JSON.parse(window.atob(c));
        } else {
            c = null;
        }

        return c;
    }

    showNewFeature = () => {
        return this.state.loggedIn && this.state.loggedInUser.active_state !== "pending" && this.state.loggedInUser.active_state !== "deleted" && this.state.isAdmin;
    }

    approved = () => {
        return this.state.loggedIn && (this.state.loggedInUser !== null && this.state.loggedInUser.complete === 1);
    }

    handleCookie = (loginObj) => {
        const {cookies} = this.props;
        this.setState({loggedInUser: loginObj});
        this.setState({loggedIn: this.state.loggedInUser.id !== undefined});
        this.setState({isAdmin: this.state.loggedIn && (this.state.loggedInUser.member_type === "committee" || this.state.loggedInUser.member_type === "admin")});
        cookies.set("user", window.btoa(JSON.stringify(loginObj)), {path: "/", secure: true, maxAge: 1800}); // setting the cookie 30 mins
    };

    handleLogin = (loginObj) => {
        if (loginObj === undefined) {
            this.props.cookies.remove("user", {path: '/'});
            this.setState({loggedIn: false, loggedInUser: null, isAdmin: false});
            let baseUrl = window.location.protocol + "//" + window.location.host
            if (window.location.href !== baseUrl && window.location.href !== baseUrl + "/members") {
                window.location.replace(baseUrl);
            }
        } else {
            if (loginObj.error !== undefined) {
                this.setState({error: [loginObj.error], isAdmin: false});
            } else {
                this.handleCookie(loginObj);
            }
        }

        setTimeout(function () {
            this.setState({error: []});
        }.bind(this), 2000);
    }


    render() {
        return (<Router>
                <RouteChangeTracker/>
                <MenuFull menuItems={menuItems} loggedIn={this.state.loggedIn} loggedInUser={this.state.loggedInUser}
                          approved={this.approved()}
                          showNewFeature={this.showNewFeature()} handleLogin={this.handleLogin}/>
                <MenuMobile menuItems={menuItems} loggedIn={this.state.loggedIn} loggedInUser={this.state.loggedInUser}
                            approved={this.approved()}
                            showNewFeature={this.showNewFeature()} handleLogin={this.handleLogin}/>

                <Container className="parent-block">
                    <Container style={{background: "orange", padding: "30px", marginBottom:"0 !important"}}>
                        Ad block
                    </Container>
                    <Container className="middle-block">
                        <Routing
                            state={this.state}
                            approved={this.approved()}
                            showNewFeature={this.showNewFeature()}
                            handleLogin={this.handleLogin}
                        />
                    </Container>
                    <Footer handleLogin={this.handleLogin} loggedInUser={this.state.loggedInUser}/>
                </Container>

            </Router>);
    }
}

export default withCookies(App)