import React, {Component} from "react";
import axios from "axios";
import {version} from "../../package.json";

const API_RESET_LOGIN = "https://www.pinetreesra.co.uk/api/login/resetLogin";
const API_CHECK_LOGIN = "https://www.pinetreesra.co.uk/api/login/checkLogin";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.resetLogin = this.resetLogin.bind(this);
        this.checkToken();
    }

    checkToken = () => {
        if (this.props.loggedInUser !== null && this.props.loggedInUser !== undefined && this.props.loggedInUser.id !== undefined) {
            axios({
                method: 'post',
                url: `${API_CHECK_LOGIN}`,
                headers: {'content-type': 'application/json'},
                data: this.props.loggedInUser
            }).then(result => {
                if (!result.data.ok) {
                    this.resetLogin();
                }
            });
        }
    }

    resetLogin = () => {
        if (this.props.loggedInUser !== null && this.props.loggedInUser !== undefined && this.props.loggedInUser.id !== undefined) {
            axios({
                method: 'post',
                url: `${API_RESET_LOGIN}`,
                headers: {'content-type': 'application/json'},
                data: this.props.loggedInUser
            });
        }
        this.props.handleLogin(undefined);
    }

    render() {
        return (
            <div>
                <p className="footer">
                    © 2023 Pine Trees Residents Association. All rights reserved. (v:{version})
                </p>
                <div style={{position: "fixed", bottom: "5px", right: "5px"}}>
                    <button onClick={this.resetLogin}>reset</button>
                </div>
            </div>
        )
    }
}

export default Footer