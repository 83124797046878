import React, {Component} from "react";
import ToDoSegment from "./to_do_segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import queryString from "../../components/query_string";

class ToDoList extends Component {
    state = {
        deepLinkIndex: queryString("deepLink") || -1,
        asAdmin: false
    }

    render() {
        return (
            <div style={{margin: "10px"}}>
                {this.props.isAdmin &&
                <Button
                    color="purple"
                    content={this.state.asAdmin ? "View as Preview" : "View as Admin"}
                    size="tiny"
                    onClick={() => {
                        this.setState({asAdmin: !this.state.asAdmin})
                    }}
                    floated="right"
                />
                }
                <Header>Current Items</Header>
                {this.props.todoList.map((obj, k) =>
                    <ToDoSegment
                        key={k}
                        loggedInUser={this.props.loggedInUser}
                        todoItem={obj}
                        previousRecord={k > 0 ? this.props.todoList[k - 1] : null}
                        fetch={this.props.fetch}
                        isAdmin={this.props.isAdmin}
                        asAdmin={this.state.asAdmin}
                        deepLinkIndex={this.state.deepLinkIndex}
                    />
                )}
                <p className="small-grey-text">{this.props.todoList.length} row{this.props.todoList.length === 1 ? "" : "s"} shown</p>
            </div>
        )
    }

}

export default ToDoList